import { Grid, Hidden } from "@material-ui/core";
import React from "react";

export default function FamilyMP() {
  return (
    <>
      <Grid container id="family_mp">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          id="family_mp_top"
          data-aos="fade-down"
          data-aos-offset="500"
          data-aos-delay="200"
          data-aos-duration="1500"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
          data-aos-anchor-placement="top-bottom"
        >
          {/* desktop */}
          <Hidden smDown>
            <img
              src="https://res.cloudinary.com/dsepncuj8/image/upload/v1648390391/Figma%20Wbsite%20Images/Family_Meal_Planning_ods1ik.png"
              alt="family MP"
            />
          </Hidden>

          {/* mobile */}
          <Hidden mdUp>
            <img
              src="https://res.cloudinary.com/dsepncuj8/image/upload/v1655880391/Figma%20Wbsite%20Images/RECENT%20WEB%20SITE%20IMAGES/Frying_dwewsw.png"
              alt="family MP"
            />
          </Hidden>

          <h1>Family meal planning</h1>
          <h2>UNMATCHED CUSTOMER EXPERIENCE</h2>
        </Grid>

        <Content
          src={
            "https://res.cloudinary.com/dsepncuj8/image/upload/v1648390378/Figma%20Wbsite%20Images/Create_a_meal_plan_hlwkjw.png"
          }
          heading={"Create a meal plan"}
          text1={"Select meals of your choice"}
          text2={"to create a plan."}
        />

        <Content
          src={
            "https://res.cloudinary.com/dsepncuj8/image/upload/v1648390384/Figma%20Wbsite%20Images/take_a_survey_2_cim3eo.png"
          }
          heading={"Take a survey"}
          text1={"Let a registered Dietician"}
          text2={"help you create a meal"}
          text3={"plan that suits your"}
          text4={"lifestyle/health needs."}
        />

        <Content
          src={
            "https://res.cloudinary.com/dsepncuj8/image/upload/v1648393998/Figma%20Wbsite%20Images/Create_a_Recipe-removebg-preview_dhig3p.png"
          }
          heading={"Create your own recipe"}
          text1={"Describe the meals you "}
          text2={"want and how it should be"}
          text3={" prepared."}
        />
      </Grid>
    </>
  );
}

export function Content({
  src,
  heading,
  text1,
  text2,
  text3,
  text4,
  getApp,
  headingLg1,
  headingLg2,
}) {
  return (
    <Grid
      item
      xs={getApp ? 4 : 12}
      sm={getApp ? 4 : 12}
      md={4}
      lg={4}
      id="family_mp_content"

      data-aos="fade-up"
      data-aos-offset="200"
      data-aos-delay="50"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false"
      data-aos-anchor-placement="top-bottom"
    >
      <section>
        <img src={src} alt={heading} />

        <aside>
          <Hidden mdUp>
            <h3>{headingLg1 ? headingLg1 : heading}</h3>
            <h3>{headingLg2}</h3>
          </Hidden>

          <Hidden smDown>
            <h3>{heading}</h3>
          </Hidden>

          <p>{text1}</p>
          <p>{text2}</p>
          <p>{text3}</p>
          <p>{text4}</p>
        </aside>
      </section>
    </Grid>
  );
}
