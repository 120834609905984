import { Hidden } from "@material-ui/core";
import React, { useContext } from "react";
import { HelperContext } from "../../../Context/Helper";
import NavMobile from "./NavMobile";

export default function NavV2() {
  const { scrolled } = useContext(HelperContext);

  return (
    <>
      <section id="nav" className={scrolled ? "nav_scroll" : ""}>
        <a href="/">
          <img
            src={
              scrolled
                ? "https://res.cloudinary.com/dsepncuj8/image/upload/v1657019701/Figma%20Wbsite%20Images/Green_xg9hq8.png"
                : "https://res.cloudinary.com/dsepncuj8/image/upload/v1648565751/Figma%20Wbsite%20Images/OOMA_2_hkdlap.png"
            }
            alt="site identity"
          />
        </a>

        <aside>
          <Hidden smDown>
            <a href="/">Meal plans</a>
            <a href="/">Our meals</a>
            <a href="/" className="btn_green">
              Contact us
            </a>
          </Hidden>

          <Hidden mdUp>
            <NavMobile />
          </Hidden>
        </aside>
      </section>
    </>
  );
}
